





















































































































import Vue from 'vue'
import Component from 'vue-class-component'
import OfferService from '@/services/Offer.service'
import { Offer, OfferStatus } from '@/interfaces/offer'
import { PaymentLinkForm, PaymentLinkType } from '@/interfaces/payment-link'
import { mask } from 'vue-the-mask'
import { CurrencyInput } from 'vue-currency-input'
import { User } from '@/interfaces/user'
import UserService from '@/services/User.service'
import { isEmpty } from '@/utils'
import PaymentLinkService from '@/services/PaymentLink.service'

interface FilterOptions {
  query: string;
}

@Component({
  components: {
    CurrencyInput
  },
  directives: {
    mask
  }
})
export default class Users extends Vue {
  private offerService = new OfferService();
  private userService = new UserService();
  private paymentLinkService = new PaymentLinkService();

  $moment;

  offers: Offer[] = [];

  form: PaymentLinkForm = {} as PaymentLinkForm

  investor!: User
  offer: Offer = {} as Offer
  type: PaymentLinkType = {} as PaymentLinkType

  formState = {
    loading: false,
    email: {
      loading: false,
      success: false
    }
  }

  state = {
    loading: false
  }

  async mounted() {
    this.state.loading = true
    this.offers = await this.loadOffers()
    this.state.loading = false

    this.form.vencimento = this.$moment().add(2, 'days').format('DD/MM/YYYY')
  }

  async loadOffers() {
    return this.offerService.all()
  }

  get validOffers() {
    return this.offers.filter(o => {
      return [OfferStatus.EM_CAPTACAO, OfferStatus.LIQUIDANDO, OfferStatus.PRIVADO].includes(OfferStatus[o.status])
    })
  }

  get paymentLinkTypes() {
    return Object.keys(PaymentLinkType).map((type) => {
      return {
        label: PaymentLinkType[type],
        value: type
      }
    })
  }

  onFormSubmit() {
    if (isEmpty(this.investor) || isEmpty(this.offer) || isEmpty(this.type) || !this.form.valor) {
      alert('Campos obrigatórios devem ser preenchidos.')
      return
    }

    this.form.url_oferta = this.offer.url_oferta
    this.form.tipo = (this.type as any).value
    this.form.investidor = {
      nome: this.investor.nome_completo,
      email: this.investor.email,
      cpf_cnpj: this.investor.cpf,
      endereco: this.investor.endereco
    }

    this.formState.loading = true

    this.paymentLinkService
      .new(this.form)
      .then(() => {
        alert('Link de pagamento criado com sucesso.')
        this.$router.push('/link-de-pagamento')
      })
      .catch((request) => {
        const error = request.response.data
        alert((typeof error === 'string' && error) || 'Erro ao criar link de pagamento. Por favor contate o suporte.')
      })
      .then(() => { this.formState.loading = false })
  }

  onEmailChange(e) {
    this.formState.email.loading = true

    const email = e.target.value

    this.userService
      .profile(email)
      .then((response) => {
        // Found
        if (response.email) {
          this.formState.email.success = true
          this.investor = response
          return
        }

        this.formState.email.success = false
        this.investor = null as any
        alert('Investidor não encontrado.')
      })
      .catch(() => {
        this.formState.email.success = false
        this.investor = null as any
      })
      .then(() => {
        this.formState.email.loading = false
      })
  }
}






















































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import PaymentLinkService from '@/services/PaymentLink.service'
import Pagination from '@/components/Pagination.vue'
import JsonExcel from 'vue-json-excel'
import { Pagination as IPagination } from '@/interfaces/pagination'
import { mask } from 'vue-the-mask'
import { PaymentLink, PaymentLinkType, PaymentLinkToCSV } from '@/interfaces/payment-link'
import Clipboard from 'v-clipboard'
import { Offer } from '@/interfaces/offer'
import OfferService from '@/services/Offer.service'

Vue.use(Clipboard)

interface FilterOptions {
  email: string | null;
  offer: Offer | null;
}

@Component({
  components: {
    Pagination,
    JsonExcel
  },
  directives: {
    mask
  }
})
export default class Users extends Vue {
  private offerService = new OfferService();
  private paymentLinkSerivce = new PaymentLinkService();

  // Fix lint error.
  PaymentLinkToCSV = PaymentLinkToCSV
  PaymentLinkType = PaymentLinkType
  offers: Offer[] = []

  links: PaymentLink[] = []

  options = {
    itemsPorPagina: 20,
    pagina: 0
  }

  filter: FilterOptions = {
    email: null,
    offer: null
  }

  state = {
    downloadLoading: false,
    isFilterOpen: false,
    loading: true,
    error: ''
  }

  pagination: IPagination = {} as IPagination

  async mounted() {
    this.offers = await this.offerService.all()
    this.loadLinks()
  }

  findOffer(url) {
    return this.offers.find(o => o.url_oferta === url)
  }

  deactivateLink(link: PaymentLink) {
    const result = window.confirm('Tem certeza que deseja desativar este link?')

    if (!result) return

    this.paymentLinkSerivce.delete(link.id)
      .then(() => {
        this.loadLinks()
      })
      .catch((request) => {
        try {
          alert(request.response.data)
        } catch (e) {
          console.log(e)
        }
      })
  }

  loadLinks(opts = this.options) {
    this.state.loading = true

    this.paymentLinkSerivce.all(opts)
      .then(data => {
        this.links = data.items
        this.pagination = data.pagination
      })
      .then(() => {
        this.state.loading = false
      })
  }

  async fetchData() {
    const { items } = await this.paymentLinkSerivce.all({ paginacao: false })

    return items
  }

  onToPage(n) {
    this.$set(this.options, 'pagina', n)

    const opts = {
      ...this.options,
      ...this.filter
    }

    this.loadLinks(opts)
  }

  startDownload() {
    this.state.downloadLoading = true
  }

  finishDownload() {
    this.state.downloadLoading = false
  }

  onFilterReset() {
    this.filter = { email: null, offer: null }
    this.onFilterSubmit()
  }

  onFilterSubmit() {
    const filter = {
      email: this.filter.email,
      urlOferta: this.filter.offer?.url_oferta
    }

    const opts = {
      ...this.options,
      ...filter
    }

    this.loadLinks(opts)
  }
}

